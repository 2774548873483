import "./src/styles/global.css";
import "./src/browser/scripts.js"

function initGTM() {
    if (window.isGTMLoaded) {
        return false;
    };
    window.isGTMLoaded = true;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.key = 'gtag-script';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA}`;
    document.head.appendChild(script);
};

function loadGTM(event) {
    initGTM();
    event.currentTarget.removeEventListener(event.type, loadGTM);
};

export const onInitialClientRender = () => {
    document.onreadystatechange = () => {
        if (document.readyState !== 'loading') {
            // setTimeout(initGTM, 1000);
            initGTM();
        }
    };

    document.addEventListener('scroll', loadGTM);
    document.addEventListener('mousemove', loadGTM);
    document.addEventListener('touchstart', loadGTM);
}


// import TiktokPixel from 'tiktok-pixel';
// import {
//   isGdprMetriks
// } from "./src/utils/store";

// const {
//   initializeAndTrackGoogleTagManager,
//   initializeAndTrackFacebookPixel,
//   initializeAndTrackTikTokPixel,
// } = require('./services')

// const { isEnvironmentValid } = require('./helper')

// const initializeAndTrack = (location) => {
//   const options = window.gatsbyPluginGDPRCookiesOptions

//   if (isEnvironmentValid(options.environments)) {
//     if (location === undefined || location === null) {
//       console.error('Please provide a reach router location to the initializeAndTrack function.')
//     } else {
//       initializeAndTrackGoogleTagManager(options.googleTagManager, location)
//       // initializeAndTrackFacebookPixel(options.facebookPixel)
//       // initializeAndTrackTikTokPixel(options.tikTokPixel)
//     }
//   }
// }

// export const onClientEntry = () => {
//   window.gatsbyPluginGDPRCookiesGoogleTagManagerAdded = false
//   window.gatsbyPluginGDPRCookiesFacebookPixelAdded = false
//   window.gatsbyPluginGDPRCookiesTikTokPixelAdded = false

//   window.gatsbyPluginGDPRCookiesGoogleTagManagerInitialized = false
//   window.gatsbyPluginGDPRCookiesFacebookPixelInitialized = false
//   window.gatsbyPluginGDPRCookiesTikTokPixelInitialized = false

//   const optionsGDPRCookies = {
//     environments: [`production`, 'development'],
//     googleTagManager: {
//       cookieName: `gatsby-gdpr-google-tagmanager`,
//       dataLayerName: `dataLayer`,
//       routeChangeEvent: `gatsbyRouteChange`,
//       trackingId: process.env.GATSBY_GA
//     },
//     facebookPixel: {
//       cookieName: `gatsby-gdpr-facebook-pixel`,
//       // pixelId: '614524730770163'
//     },
//     tikTokPixel: {
//       cookieName: `gatsby-gdpr-tiktok-pixel`
//       // CIHCSTJC77U3JP5LD3P0
//     },
//   }
//   window.gatsbyPluginGDPRCookiesOptions = optionsGDPRCookies;
// };

export const onRouteUpdate = ({ location }) => {
    if (typeof window.gtag === 'function') {
        // Отправляем событие pageview вручную при каждом переходе
        window.gtag('event', 'page_view', {
            'Branch': 'old_design_cookie'
        });
    }
}